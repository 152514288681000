/* import __COLOCATED_TEMPLATE__ from './pricing-five.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import PricingMetric from 'embercom/lib/purchase/pricing-metric';
import type Charge from 'embercom/models/billing/price/charge';
import numericFormatter from 'embercom/lib/numeric-formatter';
import type IntlService from 'embercom/services/intl';
import type Price from 'embercom/models/price';
import type Product from 'embercom/models/product';
import { PLAN_DATA, PRICING_5_X_CORE_ESSENTIAL_ID } from 'embercom/lib/billing';

interface Args {
  price: Price;
  product: Product;
}

export default class PricingFive extends Component<Args> {
  @service declare intl: IntlService;
  @service declare customerService: any;
  @service declare appService: any;

  get billingPeriodDurationInMonths() {
    return this.customerService.customer.subscription.billingPeriodDurationInMonths;
  }

  get hasLiteSeats() {
    return PLAN_DATA[this.planId].liteSeatLimit > 0;
  }

  getDiscountInfo() {
    let price = this.args.price;
    if (!price.appliedCoupon && !this.customerService.isSelfServeAnnualCustomer) {
      return {};
    }

    let discountText = '';
    let discountAmount = 0;

    if (this.customerService.isSelfServeAnnualCustomer) {
      let percentOff = price.getDiscountPercentageForBillingPeriod(
        this.billingPeriodDurationInMonths,
      );
      discountText = this.intl.t('billing.change-plan-modal.pricing-tooltip-core.percent-off', {
        percent: percentOff * 100,
      });
      discountAmount = price.getMonthlyDifferenceBetweenBillingPeriods(
        1,
        this.billingPeriodDurationInMonths,
        false,
      );
    } else if (price.hasPercentOffCoupon) {
      discountText = this.intl.t('billing.change-plan-modal.pricing-tooltip-core.percent-off', {
        percent: price.couponPercentOff,
      });
      discountAmount = price.couponDiscountAmount;
    } else {
      discountText = this.intl.t(
        'billing.change-plan-modal.pricing-tooltip-core.fixed-amount-off',
        {
          fixedAmount: numericFormatter(Math.abs(price.couponFixedAmountOff) / 100, 0, true),
        },
      );

      discountAmount = price.couponDiscountAmount;
    }

    return {
      label: this.intl.t('billing.change-plan-modal.pricing-tooltip-core.discount-shown', {
        amount: discountText,
      }),
      value: `-$${numericFormatter(discountAmount / 100, 0, true)}`,
    };
  }

  get planName() {
    return this.args.price.breakdown.firstObject?.name;
  }

  get planId() {
    return this.args.price.breakdown.firstObject?.plan_id;
  }

  get isAddOn() {
    return this.args.product.addon;
  }

  p5PricingTooltipSeatsOnly(metric: PricingMetric) {
    return {
      label: this.intl?.t('paywalls.pricing-tooltip.pricing-five.core-seats', {
        usage: metric.usage,
        cost: numericFormatter(metric.perUnitPrice / 100, 0, true),
      }),
      value: `$${numericFormatter(this.args.price.preDiscountAmount / 100, 0, true)}`,
    };
  }

  get priceTooltipRows() {
    let discountRow = {
      ...this.getDiscountInfo(),
      class: 'italic text-sm',
    };
    let { charges } = this.args.price.breakdown.firstObject;

    //Hide all metrics other than core seats for P5 core plans
    if (!this.isAddOn) {
      charges = charges.filter((charge: Charge) => charge.pricing_metric === 'core_seat_count');
    }

    //Use normal pricing metric tooltipSummary for add on. We need to render included usage
    //Use p5PricingTooltipSeatsOnly to show only prices of seats
    let summaryRows = charges
      .map((charge: Charge) => {
        if (this.isAddOn) {
          return new PricingMetric(charge, this.intl).tooltipSummary();
        } else {
          return this.p5PricingTooltipSeatsOnly(new PricingMetric(charge, this.intl));
        }
      })
      .flat();

    if (this.isAddOn) {
      summaryRows.unshift({
        label: this.intl.t('paywalls.pricing-tooltip.pricing-five.addon.price'),
        value: `$${numericFormatter(this.args.price.planStartingPrice / 100, 0, true)}`,
      });
    }

    if (this.hasLiteSeats) {
      summaryRows.push({
        label: this.intl?.t('paywalls.pricing-tooltip.pricing-five.lite-seats', {
          usage: this.appService.app.humanAdminsWithLiteSeat.length || 0,
        }),
        value: this.intl.t('paywalls.pricing-tooltip.pricing-five.free'),
      });
    }

    //@ts-ignore
    // downgrading only exists on the ImmutableProduct model
    // this should be set as a tracked property in paywall but it requires a bit more refactoring
    if (this.args.product.downgrading && this.planId === Number(PRICING_5_X_CORE_ESSENTIAL_ID)) {
      summaryRows.push({
        label: this.intl.t('paywalls.pricing-tooltip.pricing-five.lite-seats-note'),
        value: '',
        class: 'italic text-sm text-muted',
      });
    }

    return {
      planName: this.planName,
      rows: [...summaryRows, discountRow],
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::PricingTooltip::PricingFive': typeof PricingFive;
    'paywalls/pricing-tooltip/pricing-five': typeof PricingFive;
  }
}
