/* import __COLOCATED_TEMPLATE__ from './single-product.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import {
  CORE_STARTER_BASE_ID,
  MODAL_ADD_PLAN_INFO_WITH_FIN,
  MODAL_FEATURE_INFO,
  MODAL_UPGRADE_PLAN_INFO,
  PLAN_DATA,
  VBP2_PLAN_IDS,
  ANSWER_BOT_ESSENTIAL_ID,
  MODAL_ADD_PLAN_INFO_CUSTOM_ANSWERS,
} from 'embercom/lib/billing';

export default class SingleProduct extends Component {
  @service appService;
  @service customerService;
  @service paywallService;
  @service intl;

  fallbackColor = '#DADADA';

  get app() {
    return this.appService.app;
  }

  get upgrading() {
    return this.args.upgrading;
  }

  get featureKey() {
    return this.args?.feature?.key || this.args?.feature;
  }

  get featuresToHide() {
    return this.paywallService.featuresToHide();
  }

  get modalAddPlanInfo() {
    if (this.args.plan.id === ANSWER_BOT_ESSENTIAL_ID) {
      return MODAL_ADD_PLAN_INFO_CUSTOM_ANSWERS;
    }
    return MODAL_ADD_PLAN_INFO_WITH_FIN[this.args.plan.id];
  }

  get modalUpgradePlanInfo() {
    return MODAL_UPGRADE_PLAN_INFO[this.args.plan.id];
  }

  get modalInfo() {
    if (VBP2_PLAN_IDS.includes(this.args.plan.id)) {
      return this.modalAddPlanInfo;
    } else if (this.shouldShowFeatureHighlights) {
      return MODAL_FEATURE_INFO[this.featureKey];
    } else if (!this.upgrading) {
      return this.modalAddPlanInfo;
    } else {
      return this.modalUpgradePlanInfo;
    }
  }

  get parentPlan() {
    if (!this.modalInfo?.extendsPlanId) {
      return;
    }
    return this.customerService.plans.find((plan) => plan.id === this.modalInfo?.extendsPlanId);
  }

  get parentPlanFeatures() {
    if (!this.parentPlan) {
      return;
    }
    let allFeatures = this.parentPlan.features.uniqBy('name');
    return allFeatures.reject((feature) => this.featuresToHide.has(feature.key));
  }

  get planFeatures() {
    let allFeatures = this.args.plan.features.uniqBy('name');
    return allFeatures.reject((feature) => this.featuresToHide.has(feature.key));
  }

  get productVideo() {
    return this.modalInfo?.productVideo;
  }

  get shouldHighlightFeatureInFeatureList() {
    return !this.shouldShowFeatureHighlights && !this.args.disableFeatureHighlighting;
  }

  get shouldShowBenefitIcons() {
    // Every benefit should have an icon present.
    return this.modalInfo?.benefits.every((benefit) => benefit.icon);
  }

  get shouldShowFeatureHighlights() {
    return this.featureKey && this.featureKey in MODAL_FEATURE_INFO;
  }

  get subtext() {
    let feature = this.args?.feature;
    if (feature?.subtextKey) {
      return this.intl.t(
        `paywalls.upgrade-modal.features.${feature.key}.subtext.${feature.subtextKey}`,
      );
    }
  }

  get modalButtonLabel() {
    if (this.customerService.customer.hasEverHadSubscription) {
      return this.intl.t('modal.cardless-conversion-modal.buy_starter_now', {
        price: this.starterPlanPriceFormatted,
      });
    } else {
      return this.intl.t('pricing-and-packaging.paywalls.button_start_trial');
    }
  }

  get starterPlanPrice() {
    return PLAN_DATA[CORE_STARTER_BASE_ID].fromPricePerMonth;
  }

  get starterPlanPriceFormatted() {
    return this.intl.formatNumber(this.starterPlanPrice, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }
}
